import React from 'react'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../components/layout'
import TeamSlider from '../components/team-slider'
import Hero from '../components/hero'
import SEO from '../components/seo'
import VideoModal from '../components/video-modal'
import styled, { createGlobalStyle } from 'styled-components'
import AspectRatio from 'react-aspect-ratio'
// import Anime from 'react-anime'

const VideoModalWrapper = styled.section`
height:100%;
.my-12 {margin-top:0!important;margin-bottom:0!important;}

.o-wrapper {padding-left:0;padding-right:0;}
.o-wrapper > .mx-auto {max-width:100%!important}

section.video,
.o-wrapper,
.o-wrapper > .mx-auto,
.my-12 {height:100%;}
`


const GlobalStyle = createGlobalStyle`
  .arrow-move:hover svg  {
    transform: translateX(5px);
  }
`

const LogoItem = styled.div`
  @media (max-width: 768px) {
    img {
      max-height:32px;
    }
  }
`
const Arrow = styled.div`
  width:37px;
  height:28px;
  @media (max-width: 1024px) {
    width:30px;
    height:20px;
  }
  svg {
    height:100%;
    width:100%;
    object-fit:contain;
  }
`;



const AboutPage = ({ data: { page } }) => {
  const { acf, yoast_meta } = page;
  const linkColours = ['green', 'tan', 'blue'];
  return (
    <Layout>

      <GlobalStyle />

      <SEO yoast={yoast_meta} />

      {/* Hero */}
      <section className="hidden md:block mb-32 mt-4">
        <div className="o-wrapper" style={{maxWidth:'1329px'}}>
          <div className="relative pb-20">
            <AspectRatio ratio="1061/639" className="bg-tertiary rounded-sm hero-ratio-image" style={{maxWidth:'75%'}}>
              <div>
                {acf.video_url &&
                  <VideoModalWrapper>
                    <VideoModal
                      video={acf.video_url}
                      embedType={acf.video_url.includes('youtu') ? 'iframe' : 'html5'}
                      poster={acf.hero.hero.image}
                    />
                  </VideoModalWrapper>
                }

                {!acf.video_url &&
                  <Image fluid={acf.hero.hero.image.localFile.childImageSharp.fluid} className="object-cover h-100 w-100" draggable={false} />
                }

              </div>
            </AspectRatio>

            <div className="absolute bottom-0 right-0 w-full h-full flex flex-col justify-end pointer-events-none">
              <AspectRatio ratio="949/654" className={`bg-${acf.hero.hero.background_colour || 'green'} mr-0 ml-auto w-full h-full rounded-sm`} style={{maxHeight:'82%', maxWidth:'980px', zIndex:'-1'}}></AspectRatio>
            </div>

            <div className="absolute bottom-0 right-0">
              <div className="py-12 sm:px-10 px-6 bg-white inline-block md:mx-12 sm:mx-6 mx-4 mb-12 rounded-sm">
                <div className="text-left" style={{maxWidth: '26rem'}}>
                <h1 className="fade-in-up md:u-h1 u-h2 mb-6">{acf.hero.hero.heading}</h1>
                <p className="fade-in-up ani-delay-200">{acf.hero.hero.content}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="md:hidden">
        <Hero
          heading={acf.hero.hero.heading}
          content={acf.hero.hero.content}
          image={acf.hero.hero.image.localFile.childImageSharp.fluid}
          ogImage={acf.hero.hero.image}
          video_url={acf.video_url}
          buttons={acf.hero.hero.buttons}
          bg={acf.hero.hero.background_colour || 'green'}
        />
      </div>


      {/* Intro */}
      <section className="md:my-40 my-20 lg:mt-32">
        <div className="o-wrapper">
          <header className="grid md:grid-cols-2 row-gap-8 col-gap-2 mb-12">
            <div>
              <h2 className="u-h2 md:max-w-sm mx-auto">{acf.intro.heading}</h2>
            </div>
            <div>
              <div className="post-content" dangerouslySetInnerHTML={{ __html:acf.intro.content }}/>
            </div>
          </header>

          <div className="grid sm:grid-cols-2 grid-cols-1 gap-2">
            {acf.intro.images.map(img =>
              <div key={img}>
                <img src={img} alt="" className="w-full h-full object-cover"/>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Quality */}
      <section className="md:my-40 md:my-20 my-16" id="quality">
        <div className="o-wrapper">
          <div className="grid md:grid-cols-2 row-gap-8 gap-10 items-center">
            <div className="order-1 md:order-0">
              <Image fluid={acf.quality.image.localFile.childImageSharp.fluid} draggable={false} className="about-image-pad" />
            </div>
            <div>
              <div className="md:max-w-md mx-auto">
                <h2 className="u-h2 mb-6">{acf.quality.heading}</h2>
                <div dangerouslySetInnerHTML={{__html: acf.quality.content }} />
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4 sm:mt-16 mt-10">
            {acf.quality.stats.map(stat =>
              <div key={stat.stat + stat.description} className="border-b-4 border-green bg-tertiary p-6">
                <h4 className="lg:u-h2 md:u-h3 u-h2 mb-2">{stat.stat}</h4>
                <p>{stat.description}</p>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Repair */}
      <section className="md:my-40 md:my-20 my-16" id="speed">
        <div className="o-wrapper">
          <div className="grid md:grid-cols-2 row-gap-8 gap-10 items-center">
            <div>
              <div className="md:max-w-md mr-auto">
                <h2 className="u-h2 mb-6">{acf.repair_times.heading}</h2>
                <div dangerouslySetInnerHTML={{__html: acf.repair_times.content }} />
              </div>
            </div>
            <div>
              <Image fluid={acf.repair_times.image.localFile.childImageSharp.fluid} draggable={false} className="about-image-pad" />
            </div>
          </div>
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-4 sm:mt-16 mt-10">
            {acf.repair_times.stats.map(stat =>
              <div key={stat.stat + stat.description} className="border-b-4 border-green bg-tertiary p-6">
                <h4 className="lg:u-h2 md:u-h3 u-h2 mb-2">{stat.stat}</h4>
                <p>{stat.description}</p>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Privacy */}
      <section className="md:my-40 md:my-20 my-16" id="privacy">
        <div className="o-wrapper">
          <header className="max-w-xl md:mb-12 mb-10">
            <h2 className="u-h2 mb-6">{acf.privacy.heading}</h2>
            <div dangerouslySetInnerHTML={{ __html: acf.privacy.content }} />
          </header>

          <div className="grid md:grid-cols-2 gap-6">
            {acf.privacy.boxes.map((box, i) =>
            <div className={`lg:p-12 sm:p-8 p-6 bg-${i === 0 ? 'green-light': 'tan'}`} key={box.heading}>
              <div className="md:max-w-sm">
                <img src={box.icon.localFile.publicURL} alt={box.heading} />
                <h3 className="u-h3 mb-4 mt-6">{box.heading}</h3>
                <p>{box.content}</p>
              </div>
            </div>
            )}
          </div>
        </div>
      </section>

      {/* Environment */}
      <section className="md:my-40 md:my-20 my-16">
        <div className="o-wrapper">
          <div className="grid md:grid-cols-2 row-gap-8 gap-10 items-center">
            <div>
              <Image fluid={acf.environment.image.localFile.childImageSharp.fluid} draggable={false} className="about-env-image-pad"/>
            </div>
            <div>
              <div className="md:max-w-md mx-auto">
                <h2 className="u-h2 mb-6">{acf.environment.heading}</h2>
                <div dangerouslySetInnerHTML={{__html: acf.environment.content }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Companies */}
      <section className="md:my-40 md:my-20 my-16">
        <div className="o-wrapper">
          <header className="text-center">
            <h2 className="u-h3 mb-3">{acf.companies.heading}</h2>
            <p className="max-w-2xl mx-auto">{acf.companies.content}</p>
          </header>
          <div className="grid md:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-1 my-10">
            {acf.companies.logos.map(l =>
              <LogoItem className="flex justify-center items-center bg-tertiary py-4 px-10" key={`about-${l}`}>
                <img src={l} alt="" />
              </LogoItem>
            )}
          </div>
          <div className="text-center">
            <Link to={acf.companies.button.url} className="c-btn--underline">{acf.companies.button.title}</Link>
          </div>
        </div>
      </section>

      {/* Team */}
      {/* <section className="md:my-40 md:my-20 my-16" style={{maxWidth:'100vw', overflow:'hidden' }}>
        <div className="o-wrapper">
          <header className="grid md:grid-cols-2 gap-6 mb-10 md:mb-16 lg:pb-2">
            <h2 className="u-h2">{acf.team.heading}</h2>
            <div className="md:mt-1" dangerouslySetInnerHTML={{ __html: acf.team.content }} />
          </header>
        </div>

        <TeamSlider
          members={acf.team.team_members}
        />

      </section> */}


      <section className="md:mt-40 sm:mt-20 mt-16 xl:py-20 py-16 bg-tertiary">
        <div className="o-wrapper">
          <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
            {acf.links.map((link, i) =>
              <Link to={link.link.url} key={`bottom-link${link.link.title}`} className={`flex sm:flex-col arrow-move justify-between items-center sm:items-start border-b-4 bg-white p-6 md:px-8  md:py-12 border-${linkColours[i]}`}>
                <img src={link.icon.localFile.publicURL} alt={link.link.title+' icon'} className="transform origin-left scale-75 md:scale-100"/>
                <div className="flex justify-between items-center md:mt-8 sm:mt-4 w-full ml-2 sm:ml-0">
                  <h4 className="u-h4 mr-4">{link.link.title}</h4>
                  <Arrow>
                    <svg className="transition duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.828 26.828"><path d="M33 13.414H0m21-12l12 12-12 12" fill="none" stroke="#000" strokeWidth="4"/></svg>
                  </Arrow>
                </div>
              </Link>
            )}
          </div>
        </div>
      </section>


    </Layout>
  );
}

export default AboutPage

export const query = graphql`
  {
    page: wordpressPage(template: {eq: "about.php"}) {
      id
      ...Yoast
      acf {
        hero {
          ... Hero
        }
        video_url

        intro {
          heading
          content
          images
        }

        quality {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          stats {
            stat
            description
          }
        }

        repair_times {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          stats {
            stat
            description
          }
        }

        privacy {
          heading
          content
          boxes {
            heading
            content
            icon {
              id
              localFile {
                publicURL
              }
            }
          }
        }

        environment {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }

        companies {
          heading
          content
          logos
          button {
            title
            url
          }
        }

        # team {
        #   heading
        #   content
        #   team_members {
        #     name
        #     role
        #     linkedin
        #     image {
        #       localFile {
        #         childImageSharp {
        #           fluid(maxWidth:500) {
        #             ...GatsbyImageSharpFluid
        #           }
        #         }
        #       }
        #     }
        #   }
        # }

        links {
          link {
            title
            url
          }
          icon {
            id
            localFile {
              publicURL
            }
          }
        }

      }
    }
  }
`
