import React, { Component } from 'react';
import Image from 'gatsby-image';
import Flickity from 'react-flickity-component'
import styled from 'styled-components'

const Slide = styled.div`
  user-select:none;
  width:75%;

  @media screen and (min-width:640px) {
    width:50%;
  }
  @media screen and (min-width:768px) {
    width: 384px;
  }
`

const OffscreenRight = styled.div`
  padding-left:1.33333rem;
  padding-right:1.33333rem;
  @media screen and (max-width: 47.99em) {
    padding-left:1.1111rem;
    padding-right:1.1111rem;
  }

  @media screen and (min-width:1249px) {
    margin-left: calc(((100vw) - 1249px) / 2);
  }

  * { outline:none }
`

const ArrowIcon = styled.div`
height:50px;
width:50px;
`


class TeamSlider extends Component {
  constructor(props) {
    super(props);

    this.flkty = null;

    this.sliderOptions = {
      pageDots: false,
      prevNextButtons: false,
      wrapAround: false,
      initialIndex: 0,
      cellAlign: 'left'
    }
  }

  render() {
    return (
      <section className="relative">
        <OffscreenRight>
          <Flickity
            flickityRef={c => this.flkty = c}
            className="-ml-6"
            elementType="div"
            options={this.sliderOptions}
            disableImagesLoaded={true}
          >
            {this.props.members.map(tm =>
            <Slide key={tm.name} className="pl-6">
              <div className="relative">
                {tm.image && tm.image.localFile && tm.image.localFile.childImageSharp && tm.image.localFile.childImageSharp.fluid &&
                  <Image fluid={tm.image.localFile.childImageSharp.fluid} className="absolute inset-0" draggable={false} />
                }
                <div className="absolute bottom-0 left-0 right-0 py-5 px-6 flex justify-between items-center">
                  <div>
                    <h4 className="text-lg font-medium leading-snug">{tm.name}</h4>
                    <h6>{tm.role}</h6>
                  </div>
                  {tm.linkedin &&
                    <a href={tm.linkedin} target="_blank" rel="noopener noreferrer" className="hover:opacity-75 inline-block">
                      <img src={require('../images/linkedin-icon.svg')} alt="LinkedIn"/>
                    </a>
                  }
                </div>
              </div>
            </Slide>
          )}
          </Flickity>
        </OffscreenRight>

        <div className="arrow absolute inset-0 pointer-events-none">
          <div className="o-wrapper flex flex-col h-full justify-center items-end">
            <ArrowIcon ref={(s)=> this.arrow = s} className="pointer-events-auto cursor-pointer" onClick={()=> this.flkty.next(true) }>
              <svg viewBox="0 0 89.1 89.1" xmlns="http://www.w3.org/2000/svg"><circle cx="44.55" cy="44.55" r="44.55"/><path d="m40.788 35.838 9.5 8.712-9.5 8.712" fill="none" stroke="#fff" strokeWidth="4.752"/></svg>
            </ArrowIcon>
          </div>
        </div>

      </section>
    );
  }
}

export default TeamSlider;